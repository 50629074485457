<template>
    <div>
        <div class="container">
            <h1 class="page-title">{{ $t("title") }}</h1>
        </div>

        <section class="section">
            <div class="container">
                <Clause
                    :heading="$t('clauses.clause01.heading')"
                    :html="$t('clauses.clause01.html')"
                />

                <Clause
                    :heading="$t('clauses.clause03.heading')"
                    :html="$t('clauses.clause03.html')"
                />

                <div class="questions-list-item">
                    <h3>{{ $t("clauses.clause04.heading") }}</h3>
                    <div class="ts-quiet">
                        <i18n path="clauses.clause04.html1" tag="p">
                            <i18n-link class="ts-underline" place="link" :to="{ name: 'contact' }"
                                >https://soracommobile.com/ja/contact/</i18n-link
                            >
                        </i18n>
                        <p v-html="$t('clauses.clause04.html2')"></p>
                        <p v-html="$t('clauses.clause04.html3')"></p>
                    </div>
                </div>

                <div class="questions-list-item">
                    <h3>{{ $t("clauses.clause05.heading") }}</h3>
                    <div class="ts-quiet">
                        <i18n path="clauses.clause05.html" tag="div">
                            <i18n-link class="ts-underline" place="link" :to="{ name: 'pricing' }"
                                >料金ページ</i18n-link
                            >
                        </i18n>
                    </div>
                </div>

                <Clause
                    :heading="$t('clauses.clause06.heading')"
                    :html="$t('clauses.clause06.html')"
                />

                <Clause
                    :heading="$t('clauses.clause07.heading')"
                    :html="$t('clauses.clause07.html')"
                />

                <div class="questions-list-item">
                    <h3>{{ $t("clauses.clause08.heading") }}</h3>
                    <div class="ts-quiet">
                        <i18n path="clauses.clause08.html" tag="div">
                            <i18n-link
                                class="ts-underline"
                                place="link"
                                :to="{ name: 'compatibility' }"
                                >{{ $t("menu.compatibility") }}</i18n-link
                            >
                        </i18n>
                    </div>
                </div>

                <Clause
                    :heading="$t('clauses.clause09.heading')"
                    :html="$t('clauses.clause09.html')"
                />

                <Clause
                    :heading="$t('clauses.clause10.heading')"
                    :html="$t('clauses.clause10.html')"
                />

                <div class="questions-list-item">
                    <h3>{{ $t("clauses.clause11.heading") }}</h3>
                    <div class="ts-quiet">
                        <i18n path="clauses.clause11.html" tag="div">
                            <i18n-link class="ts-underline" place="link" :to="{ name: 'terms' }">{{
                                $t("menu.terms")
                            }}</i18n-link>
                        </i18n>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import Clause from "../components/Clause"

export default {
    components: {
        Clause,
    },
    meta() {
        return this.$generateMeta({
            title: this.$i18n.t("meta.title"),
        })
    },
    data() {
        return {
            imageToggle: "balance",
        }
    },
    i18n: {
        messages: {
            en: {
                meta: { title: "特定商取引に関する法律に基づく表示" },
                title: "特定商取引に関する法律に基づく表示",
                clauses: {
                    clause01: {
                        heading: "販売事業者",
                        html: `
                    <p class="ts-quiet">SORACOM CORPORATION, LTD.</p>
                    `,
                    },
                    clause03: {
                        heading: "所在地",
                        html: `
                    <p class="ts-quiet">16 Great Queen Street, Covent Garden, London, United Kingdom, WC2B 5AH</p>
                    `,
                    },
                    clause04: {
                        heading: "お問い合わせ先",
                        html1: `お問い合わせフォーム: {link}`,
                        html2: `<p>Email: <a class="ts-underline" href="mailto:support@soracommobile.com">support@soracommobile.com</a></p>`,
                        html3: `* 責任者及び電話番号については、上記お問い合わせ先宛にご請求いただければ、遅滞なく開示いたします。`,
                    },
                    clause05: {
                        heading: "販売価格",
                        html: `{link}記載の価格をご参照ください。`,
                    },
                    clause06: {
                        heading: "商品代金以外の必要料金",
                        html: `
                    <p class="ts-quiet">なし</p>
                    `,
                    },
                    clause07: {
                        heading: "商品の引渡時期",
                        html: `
                    <p class="ts-quiet">クレジットカード利用の承認が下りた後、24時間以内（通常数分以内）</p>
                    `,
                    },
                    clause08: {
                        heading: "利用できる端末およびソフトウェアの動作環境",
                        html: `
                    {link}に記載されたモバイル端末およびその OS バージョン
                    `,
                    },
                    clause09: {
                        heading: "お支払い方法",
                        html: `
                    <p class="ts-quiet">Apple Payまたはクレジットカード（VISA, MasterCard, American Express）</p>
                    `,
                    },
                    clause10: {
                        heading: "お支払い時期",
                        html: `
                    <p class="ts-quiet">購入時</p>
                    `,
                    },
                    clause11: {
                        heading: "返品・交換・キャンセル",
                        html: `サービスの使用開始前の場合、購入後7日以内に限り返品および返金を承ります。 サービスに瑕疵・不具合があった場合は{link}第15章及び第16章記載の条件に従います。
                    `,
                    },
                },
            },
        },
    },
}
</script>
