<template>
    <div class="questions-list-item">
        <h3 v-if="!heading.includes('clauses.clause')">{{ heading }}</h3>
        <div class="block-xl" v-else></div>
        <div class="content" v-html="html"></div>
    </div>
</template>

<script>
export default {
    props: {
        heading: { type: String, required: true },
        html: { type: String, required: true },
    },
    computed: {},
    methods: {},
}
</script>
